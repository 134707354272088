import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 720.000000 725.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,725.000000) scale(0.100000,-0.100000)">

<path d="M1797 7243 c992 -2 2614 -2 3605 0 992 1 181 2 -1802 2 -1983 0
-2794 -1 -1803 -2z"/>
<path d="M1305 6499 c-155 -23 -252 -42 -277 -55 -14 -8 -33 -14 -41 -14 -17
0 -123 -42 -172 -68 -61 -32 -71 -38 -160 -100 -102 -72 -248 -215 -316 -312
-58 -81 -145 -234 -183 -320 -16 -36 -34 -76 -41 -90 -25 -53 -88 -258 -82
-272 3 -9 25 -18 48 -22 24 -4 48 -11 54 -15 10 -9 48 -17 117 -28 l36 -5 27
89 c15 48 31 92 36 98 5 5 9 15 9 21 0 7 18 52 40 99 22 47 40 88 40 91 0 11
106 174 148 229 82 105 207 216 297 265 22 11 47 25 55 30 26 15 109 50 119
50 5 0 31 6 57 14 149 43 185 47 384 43 190 -3 236 -9 435 -52 39 -9 89 -18
113 -22 40 -5 42 -4 42 19 0 13 4 28 9 34 8 8 18 45 41 149 5 22 5 43 2 46
-15 12 -157 46 -250 59 -35 5 -67 12 -73 16 -16 10 -287 33 -374 32 -44 0
-107 -5 -140 -9z"/>
<path d="M1340 6030 c-14 -4 -40 -8 -59 -9 -108 -3 -336 -114 -429 -209 -54
-55 -102 -111 -102 -118 0 -3 -15 -27 -34 -53 -40 -55 -99 -170 -125 -244 -48
-139 -49 -147 -7 -147 13 0 49 -9 81 -20 87 -30 97 -28 118 28 10 26 23 55 28
64 5 10 9 24 9 31 0 16 45 102 90 173 70 111 203 216 320 252 113 35 347 40
455 10 22 -6 62 -11 88 -12 48 -1 49 0 63 39 7 22 14 46 14 53 0 8 8 30 17 49
22 47 9 66 -50 75 -25 3 -50 9 -56 12 -5 4 -63 13 -128 21 -126 16 -252 18
-293 5z"/>
<path d="M4880 5541 c-14 -5 -47 -9 -73 -10 -66 -1 -227 -63 -312 -119 -93
-62 -168 -148 -214 -247 -46 -96 -62 -181 -70 -370 l-6 -140 -75 0 -75 0 -5
35 c-9 51 -105 242 -153 303 -86 109 -191 195 -297 244 -123 57 -172 72 -308
98 -159 29 -529 14 -604 -26 -10 -5 -24 -9 -33 -9 -8 0 -29 -6 -47 -14 -18 -8
-53 -22 -78 -32 -25 -10 -47 -21 -50 -24 -3 -4 -27 -18 -54 -33 -100 -55 -252
-205 -304 -299 -13 -24 -30 -50 -36 -58 -16 -20 -59 -120 -82 -190 -9 -30 -23
-69 -31 -87 -7 -17 -13 -44 -13 -60 0 -15 -4 -34 -8 -40 -11 -17 -107 -45
-140 -41 l-27 3 -3 523 c-2 407 -5 522 -15 523 -6 0 -142 0 -302 -1 -211 -1
-292 -5 -297 -13 -9 -14 -11 -1622 -2 -1654 5 -18 14 -23 38 -23 18 0 36 4 41
9 6 5 57 24 115 41 58 18 110 36 115 39 6 3 30 12 55 19 25 7 59 17 75 21 163
48 246 70 278 76 20 4 40 11 43 16 3 5 22 9 42 9 20 0 57 6 82 14 49 16 253
58 365 74 39 6 97 16 130 22 33 6 78 13 100 16 22 3 81 11 130 19 50 7 142 18
205 24 63 7 147 16 186 22 39 5 126 12 195 15 68 3 153 9 189 14 218 31 1118
12 1400 -29 41 -6 111 -16 155 -21 107 -14 246 -38 320 -55 33 -7 87 -19 120
-25 33 -7 80 -18 105 -25 25 -8 72 -19 105 -26 33 -7 72 -18 87 -26 15 -7 35
-13 45 -13 18 0 235 -77 296 -104 18 -8 58 -26 90 -40 58 -26 211 -110 231
-127 43 -37 141 -97 149 -92 10 6 3 82 -12 110 -5 10 -23 47 -40 83 -66 138
-269 308 -491 412 -85 40 -206 88 -221 88 -9 0 -22 7 -29 15 -7 8 -23 15 -36
15 -13 0 -24 1 -25 3 0 1 -2 54 -4 117 -3 63 -7 190 -10 282 -10 295 -49 427
-160 543 -81 86 -213 164 -325 195 -97 26 -374 47 -420 31z m199 -448 c27 -25
31 -38 40 -118 6 -49 11 -163 11 -252 l0 -163 -25 -6 c-14 -3 -33 -3 -42 1
-10 4 -53 11 -97 16 -43 4 -81 11 -85 14 -11 12 -7 421 5 436 6 8 14 25 17 39
15 61 124 81 176 33z m-1954 -162 c3 -5 19 -13 37 -16 80 -18 185 -116 233
-217 28 -61 23 -64 -127 -72 -73 -4 -151 -11 -173 -16 -22 -5 -74 -10 -115
-10 -41 0 -111 -7 -155 -14 -44 -8 -109 -16 -145 -17 l-65 -3 -3 32 c-8 85
135 283 224 312 22 7 43 16 49 21 12 12 232 12 240 0z"/>
<path d="M4227 3937 c-17 -12 -18 -38 -14 -357 5 -435 20 -549 89 -690 43 -87
142 -183 237 -233 40 -20 78 -37 83 -37 5 0 24 -6 41 -14 105 -45 420 -62 556
-31 166 39 274 93 382 193 43 41 79 80 79 88 0 8 4 14 9 14 24 0 91 208 91
283 0 23 4 57 10 75 5 19 10 117 11 219 1 147 -1 187 -12 194 -17 11 -629 12
-646 1 -8 -5 -13 -79 -16 -252 -6 -274 -12 -327 -44 -364 -19 -22 -31 -26 -76
-26 -30 0 -61 6 -74 15 -40 28 -46 89 -52 500 l-6 390 -80 11 c-44 7 -172 15
-285 18 -113 4 -219 9 -235 12 -17 2 -39 -2 -48 -9z"/>
<path d="M3684 3930 c-77 -4 -150 -12 -161 -18 -18 -10 -21 -25 -27 -154 -8
-158 -20 -297 -31 -343 -48 -204 -121 -318 -247 -383 -49 -25 -62 -27 -168
-27 -116 0 -143 6 -225 47 -103 53 -178 165 -226 340 -11 37 -19 82 -19 100 0
18 -4 48 -10 66 -5 19 -10 71 -11 115 -2 45 -4 88 -7 95 -6 17 -86 16 -142 -2
-25 -8 -67 -19 -95 -25 -172 -35 -215 -44 -259 -57 -26 -8 -58 -14 -70 -14
-11 0 -31 -8 -43 -18 -23 -17 -23 -19 -13 -126 6 -60 13 -113 16 -118 3 -5 9
-33 14 -61 7 -46 14 -70 40 -142 82 -221 205 -378 375 -481 195 -117 493 -170
780 -139 184 19 268 41 391 100 106 51 155 84 227 152 188 177 285 369 341
673 17 96 32 364 22 404 l-6 26 -153 -2 c-83 0 -215 -4 -293 -8z"/>
<path d="M1710 3594 c-30 -9 -67 -18 -83 -21 -15 -3 -30 -9 -33 -14 -3 -5 -14
-9 -24 -9 -30 0 -386 -122 -402 -138 -4 -4 -7 -188 -6 -408 l3 -399 304 -3
c237 -2 306 1 313 10 9 15 11 958 2 982 -7 19 -3 19 -74 0z"/>
<path d="M1272 2468 c-15 -15 -16 -62 -2 -89 9 -15 22 -19 64 -19 34 0 56 -5
59 -12 2 -7 5 -80 6 -163 2 -102 6 -152 14 -157 20 -13 84 -9 96 5 6 8 12 75
13 168 l3 154 60 5 c54 5 61 8 69 33 6 17 6 37 0 55 l-9 27 -181 3 c-131 2
-183 -1 -192 -10z"/>
<path d="M1950 2461 c-6 -12 -10 -101 -10 -220 0 -250 -16 -231 200 -231 171
0 203 8 208 51 6 50 -17 59 -154 59 -152 0 -150 -2 -137 141 l8 94 123 3 c135
3 162 13 162 63 0 54 -20 59 -213 59 -164 0 -177 -1 -187 -19z"/>
<path d="M2646 2464 c-14 -13 -16 -47 -16 -217 0 -111 5 -209 10 -217 7 -12
40 -16 168 -18 181 -4 232 7 232 51 0 47 -26 57 -152 57 -64 0 -123 3 -132 6
-14 5 -16 30 -16 168 0 113 -4 166 -12 174 -17 17 -64 15 -82 -4z"/>
<path d="M3320 2458 c-21 -34 -16 -406 6 -428 11 -12 48 -16 163 -18 81 -2
163 1 182 5 30 8 34 13 37 48 2 24 -2 41 -10 46 -7 5 -68 9 -134 9 -87 0 -125
4 -132 13 -7 8 -9 48 -4 117 l7 105 128 5 c114 4 130 7 143 25 15 21 11 59 -8
82 -8 10 -58 13 -187 13 -172 0 -177 -1 -191 -22z"/>
<path d="M4133 2466 c-97 -35 -137 -106 -131 -231 5 -97 31 -154 86 -190 32
-21 54 -26 143 -31 128 -8 179 4 179 42 0 56 -13 64 -108 64 -117 1 -163 21
-180 79 -10 32 -10 50 -1 81 20 66 37 75 153 78 112 4 136 15 136 62 0 50 -25
60 -141 59 -57 -1 -119 -7 -136 -13z"/>
<path d="M4830 2459 c-95 -42 -135 -106 -135 -215 1 -57 6 -78 27 -115 35 -60
96 -101 161 -110 91 -12 130 -2 190 52 102 91 115 215 32 324 -12 17 -48 43
-78 58 -67 33 -132 35 -197 6z m161 -122 c80 -54 57 -180 -39 -207 -30 -8 -44
-6 -77 10 -67 32 -90 91 -60 150 36 70 112 90 176 47z"/>
<path d="M5449 2463 c-11 -13 -14 -59 -12 -217 3 -192 4 -202 24 -219 22 -17
50 -16 77 5 8 7 12 43 12 114 0 56 4 105 9 108 4 3 17 -4 27 -15 28 -31 82
-61 96 -53 7 4 30 21 51 37 50 39 57 29 57 -84 0 -110 16 -133 83 -116 22 6
27 13 28 40 0 17 2 105 5 194 5 135 3 166 -11 192 -12 25 -22 31 -48 31 -26 0
-44 -12 -101 -70 -39 -39 -73 -70 -76 -70 -3 0 -39 32 -79 70 -76 74 -114 88
-142 53z"/>
<path d="M2134 2301 c-30 -5 -44 -21 -44 -50 0 -53 30 -69 127 -67 60 1 75 4
87 21 21 28 20 59 -4 80 -19 17 -113 26 -166 16z"/>
<path d="M3525 2302 c-52 -5 -65 -17 -65 -62 0 -46 25 -60 108 -60 69 0 103
16 116 53 20 55 -41 81 -159 69z"/>
<path d="M0 15 c0 -13 407 -15 3600 -15 3193 0 3600 2 3600 15 0 13 -407 15
-3600 15 -3193 0 -3600 -2 -3600 -15z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
